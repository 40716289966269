import React, {useEffect} from 'react';
import {Col, Spinner} from "reactstrap";
import {Link, useParams, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LOAD_FAILED, LOAD_PROGRESS, LOAD_SUCCESS} from "../../../../../store/loadActionTypes";
import {fetchIndustries, setSelectedIndustry} from "../../../../../store/industriesSlice";
import DropdownWrapper from "../../../../Shared/DropdownWrapper/DropdownWrapper";

export default function IndustrySection() {
  const dispatch = useDispatch();
  let { groupId , industryId, pageId} = useParams();
  const navigate = useNavigate();

  let industriesContent;
  let industriesContentMobile;

  const industries = useSelector((state) => state.industries.industries);
  const selectedIndustry = useSelector((state) => state.industries.selected);
  const industryStatus = useSelector((state) => state.industries.status);
  const industryError = useSelector((state) => state.industries.error);

  useEffect(() => {
    if (groupId && !industries[groupId]) {
      dispatch(fetchIndustries(groupId));
    }

    if (groupId && industries[groupId] && !pageId) {
      navigate(`/sic/${groupId}/industry/${industries[groupId][0].slug}/page/1`);
    }

    if (groupId && industries[groupId] && industryId && pageId) {
      dispatch(setSelectedIndustry(industries[groupId].find(industry => industry.slug === industryId)));
    }
  }, [groupId, pageId, industries, dispatch, navigate, industryId]);

  const selectIndustry = (industry) => {
    navigate(`/sic/${groupId}/industry/${industry.slug}/page/1`, {state: selectedIndustry});
  }

  if (industryStatus === LOAD_PROGRESS) {
    industriesContent = <Col><Spinner color={'primary'}>Loading...</Spinner></Col>;
    industriesContentMobile = industriesContent;
  } else if (industryStatus === LOAD_SUCCESS) {
    industriesContent = <div className="industries-wrapper">
      <ul className={'list-unstyled'}>
        {industries[groupId] && industries[groupId].map(item => (
          <li key={item.sic} className={`industry-item${item.slug === industryId ? ' active' : ''}`}>
            <Link to={`/sic/${groupId}/industry/${item.slug}/page/1`} state={selectedIndustry}>
              {item.name} ({item.count})
            </Link>
          </li>
        ))}
      </ul>
    </div>

    industriesContentMobile = industries[groupId] && selectedIndustry &&
      <DropdownWrapper items={industries[groupId]} selected={selectedIndustry} onChange={selectIndustry} />
  } else if (industryStatus === LOAD_FAILED) {
    industriesContent = <div>{industryError}</div>
    industriesContentMobile = industriesContent;
  }

  return (
    <>
      <div className="d-none d-md-block">
        {industriesContent}
      </div>
      <div className="d-block d-md-none mt-4 mb-3">
        {industriesContentMobile}
      </div>
    </>
  );
}
