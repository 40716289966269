import React, {useCallback, useEffect, useState} from "react";
import { Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from "reactstrap";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SearchModal.scss';
import SearchResults from '../SearchResults/SearchResults';

let debounceTimer = null;

export default function SearchModal({modalOpen, onModal}) {
  const [searchInput, setSearchInput] = useState('');
  const [results, setResults] = useState([]);

  const toggle = () => {
    onModal(!modalOpen);
    setResults([]);
    setSearchInput('');
  }

  const fetchData = useCallback( async () => {
    const respponse = await fetch(`${process.env.REACT_APP_API_URL}/search/${searchInput}`);

    const data = await respponse.json();

    setResults(data.response.docs);
  }, [searchInput])

  const handleChange = event => {
    event.preventDefault();
    setSearchInput(event.target.value);
  }

  const closeModal = () => {
    onModal(false);
    setResults([]);
    setSearchInput('');
  }


  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      if (searchInput) {
        fetchData();
      } else {
        setResults([]);
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchInput, fetchData]);

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>
          Search TickerStudy.com
        </ModalHeader>
        <ModalBody>
          <InputGroup size="lg">
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
            <Input type="search"
                   placeholder="Search..."
                   onChange={handleChange}/>
          </InputGroup>
         <SearchResults myResults={results} pattern={searchInput} onSelect={() => closeModal()}/>
        </ModalBody>
      </Modal>
    </div>
  );
}
