import React, {useEffect, useState} from 'react';
import IndexTile from "./IndexTile/IndexTile";
import './indexes.scss';
import {Helmet} from "react-helmet";


function Indexes() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/index`)
      .then(res => res.json())
      .then((result) => {
          setItems(result)
        })
  }, []);

  return (
    <>
      <Helmet>
        <title>Indexes on Ticker Study</title>
        <meta
          name="description"
          content={`The list of indexes on Ticker Study`}
        />
      </Helmet>
      <h1>Breakdown of SP500 components</h1>

      <h3>Indexes</h3>

      <div className="d-flex flex-row mb-3">
        {items.filter(idx => idx.idx_type === 'index').map(item => (
          <IndexTile key={item.id} indexData={item}/>
        ))}
      </div>

      <h3>Exchanges</h3>

      <div className="d-flex flex-row">
        {items.filter(idx => idx.idx_type === 'exchange').map(item => (
          <IndexTile key={item.id} indexData={item}/>
        ))}
      </div>
    </>
  );
}

export default Indexes;
