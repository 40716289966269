import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import Header from "../Header/header";
// import Footer from "../../Footer/Footer";
import Routes from "./routes";

import './Layout.scss';
import {initAnalytics, trackPageView} from "../../../analytics";


export default function Layout() {
  initAnalytics();
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search)
  }, [location]);

  return (
    <>
    <Container fluid className={'navigation-wrapper'}>
      <Container>
        <Row>
          <Header />
        </Row>
      </Container>
    </Container>
    <Container>

      <Row>
        <Col>
          <div className="page-wrapper">
            <Routes/>
          </div>
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Footer />*/}
      {/*</Row>*/}
    </Container>
    </>

  );
}
