import React, {useCallback, useEffect, useState} from "react";
import './SearchResults.scss';
import {Link, useNavigate} from "react-router-dom";

export default function SearchResults ({myResults, pattern, onSelect}) {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  }

  const handleClick = useCallback((e) => {
    e.preventDefault();
    onSelect();
    navigate(`/company/${myResults[activeIndex].ticker}`);
  }, [activeIndex, myResults, navigate, onSelect])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 40) {
        e.preventDefault();
        setActiveIndex((prevActiveIndex) => (
          prevActiveIndex === myResults.length - 1 ? 0 : prevActiveIndex + 1
        ));
      } else if (e.keyCode === 38) {
        e.preventDefault();
        setActiveIndex((prevActiveIndex) => (prevActiveIndex === 0 ? myResults.length - 1 : prevActiveIndex - 1));
      } else if (e.keyCode === 13) {
        e.preventDefault();
        handleClick(e);
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [myResults.length, activeIndex, handleClick]);

  function Result ({result, isActive, index}) {
    return (
      <li className={isActive ? 'active' : null}>
        <Link to={`/company/${result.ticker}`} onClick={(e) => {handleClick(e)}} onMouseEnter={()=>handleMouseEnter(index)}>
          {result.name}
        </Link>
      </li>
    )
  }

  if (pattern.length > 0) {
    if (myResults.length) {
      return (
        <div  className={'results-wrapper'}>
          <div className={'results'}>
            <ul className={'list-unstyled'}>
              {
                myResults.map((result, index) => (
                  <Result key={result.id} result={result} isActive={index === activeIndex} index={index}/>
                ))
              }
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className={'results-wrapper' }>
          <p className={'info'}>Your search didn't return any results.</p>
        </div>
      );
    }
  }
}
