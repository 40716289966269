import React from "react";
import {Col, Row} from "reactstrap";
import CompanyTile from "../CompanyTile/CompanyTile";
import Pagination from "../Pagination/Pagination";
import './ListOfCompanies.scss';

export default function ListOfCompanies({items, pages, currentPage, onPageClick}) {

  const handlePageClick = (newPage) => {
    onPageClick(newPage);
  }

  return (
    <>
      <Row>
        <Col>
          <div className="row row-cols-2 row-cols-sm-3 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
            {
              items.map((company) => (
                <div className="col" key={company.id} >
                  <CompanyTile companyData={company} />
                </div>
              ))
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            pages > 1 &&
            <Pagination pages={pages} currentPage={currentPage} onPageClick={handlePageClick}/>
          }
        </Col>
      </Row>
    </>
  )
}
