import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from "react-router-dom";

import './indexPage.scss';
import { Helmet, HelmetProvider } from "react-helmet-async";

export function loader({ params }) {
  const contactId = params.contactId;
  return {contactId};
}

export default function IndexesPage() {
  const [name, setName] = useState('');
  let { indexId } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/index/${indexId}`)
      .then(res => res.json())
      .then((result) => {
        setName(result.name)
      })
  }, [indexId])

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{name} on Ticker Study</title>
          <meta
            name="description"
            content={`The list of ${name} components on Ticker Study`}
          />
        </Helmet>
      </HelmetProvider>

      <h2 className="mb-4">{name}</h2>

      <Outlet />
    </>
  );
}

