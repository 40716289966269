import React from 'react';
import {Link} from "react-router-dom";
import './IndexTile.scss';

export default function IndexTile ({name, path, count, selected= false}) {
  return (
    <Link className={"index-tile p-2 me-3" + (selected ? ' active' : '')} to={path}>
      <span className="index-name">{name}</span>
      <span className="index-count">{count} companies</span>
    </Link>
  );
}
