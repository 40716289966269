import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import indexesReducer from './indexesSlice';
import sicReducer from './sicSlice';
import industryReducer from './industriesSlice';

export default configureStore({
  reducer: {
    counter: counterReducer,
    indexes: indexesReducer,
    sic: sicReducer,
    industries: industryReducer,
  }
})
