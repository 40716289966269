import React, {useEffect} from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import IndexTile from "../Indexes/IndexTile/IndexTile";
import {Col, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {fetchIndexes, selectIndexesByType} from "../../../store/indexesSlice";
import {fetchSic, selectAllSic} from "../../../store/sicSlice";
import './Home.scss';
import {LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS} from "../../../store/loadActionTypes";

export default function Home() {
  const dispatch = useDispatch();
  const indexes = useSelector((state) => selectIndexesByType(state, 'index'));
  const sic = useSelector(selectAllSic);
  const exchanges = useSelector((state) => selectIndexesByType(state, 'exchange'));
  const indexesStatus = useSelector((state) => state.indexes.status);
  const indexesError = useSelector((state) => state.indexes.error);
  const sicStatus = useSelector((state) => state.sic.status);
  const sicError = useSelector((state) => state.sic.error);

  let indexesContent;
  let exchangesContent;
  let sicContent;

  useEffect(() => {
    if (indexesStatus === LOAD_IDLE) {
      dispatch(fetchIndexes());
    }

    if (sicStatus === LOAD_IDLE) {
      dispatch(fetchSic());
    }
  }, [indexesStatus, sicStatus, dispatch, indexesError, sicError]);

  if (indexesStatus === LOAD_PROGRESS) {
    indexesContent = <Col><Spinner color={'primary'}>Loading...</Spinner></Col>;
    exchangesContent = indexesContent;
  } else if (indexesStatus === LOAD_SUCCESS) {
    indexesContent =  indexes.map(item => (
      <Col key={item.id}>
        <IndexTile name={item.name} path={`/index/${item.shorthand}/page/1`} count={item.companies}/>
      </Col>
    ));

    exchangesContent = exchanges.map(item => (
      <Col key={item.id}>
        <IndexTile name={item.name} path={`/index/${item.shorthand}/page/1`} count={item.companies}/>
      </Col>
    ));
  }  else if (indexesStatus === LOAD_FAILED) {
    indexesContent = <div>{indexesError}</div>
    exchangesContent = indexesContent;
  }

  if (sicStatus === LOAD_PROGRESS) {
    sicContent = <Col><Spinner color={'primary'}>Loading...</Spinner></Col>;
  } else if (sicStatus === LOAD_SUCCESS) {
    sicContent =  sic.map(item => (
      <Col key={item.id}>
        <IndexTile name={item.name} path={`/sic/${item.slug}`} count={item.count}/>
      </Col>
    ));
  }  else if (sicStatus === LOAD_FAILED) {
    sicContent = <div>{indexesError}</div>
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Indexes on Ticker Study</title>
          <meta
            name="description"
            content="Ticker Study: A comprehensive tool for categorizing and analyzing stock market data, offering insights, trends, and personalized features for investors."
          />
        </Helmet>
      </HelmetProvider>

      <h2 className="mb-3">Breakdown of SP500 components</h2>

      {/*<Counter/>*/}

      <p>This is the breakdown of an SP500 components grouped by index and stock exchange.</p>

      <h3 className="mt-4">Industries</h3>

      <p>Browse companies by <abbr title="Standard Industrial Classification">SIC</abbr> Groups and
        Industries. You can learn more about SIC on <a
          href="https://en.wikipedia.org/wiki/Standard_Industrial_Classification" target="_blank"
          rel="noopener noreferrer">
          Wikipedia</a> or on <a
          href="https://www.sec.gov/corpfin/division-of-corporation-finance-standard-industrial-classification-sic-code-list"
          target="_blank" rel="noopener noreferrer">SEC</a> websites.
      </p>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        {sicContent}
      </div>

      <h3 className="mt-4">Indexes</h3>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        {indexesContent}
      </div>

      <h3 className="mt-4">Exchanges</h3>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        {exchangesContent}
      </div>
    </>
  );
}
