import React from "react";
import './App.scss';
import Layout from "./components/Layout/Layout/Layout";
import {
  BrowserRouter as Router,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
