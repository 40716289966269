import  ReactGA from "react-ga4";

export function initAnalytics() {
  if (process.env.REACT_APP_ANALYTICS_ID && process.env.REACT_APP_ANALYTICS_ID.length > 0) {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
  } else {

  }
}

export function trackPageView(fullPath) {
  if (process.env.REACT_APP_ANALYTICS_ID && process.env.REACT_APP_ANALYTICS_ID.length > 0) {
    ReactGA.send({hitType: 'pageview', page: fullPath});
  }
}
