import React from "react";
import {useRoutes} from "react-router-dom";
import Home from "../../Pages/Home/Home";
import ErrorPage from "../../Pages/Error/ErrorPage";
import IndexesPage from "../../Pages/IndexesPage/IndexesPage";
import IndexCompanies from "../../Pages/IndexesPage/IndexCompanies/IndexCompanies";
import Indexes from "../../Pages/Indexes/Indexes";
import Company from "../../Pages/Company/Company";
import About from "../../Pages/About/About";
import SicRouter from "../../Pages/Sic/SicRouter";

export default function Routes() {
  let routes = [
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />
    },
    {
      path: '/index/:indexId',
      element: <IndexesPage />,
      children: [
        {
          path: 'page/:pageId',
          element: <IndexCompanies />
        }
      ]
    },
    { path: '/index', element: <Indexes /> },
    { path: '/company/:companyId', element: <Company /> },
    { path: "/about", element: <About /> },
  ];

  routes = routes.concat(SicRouter);

  return useRoutes(routes);
}
