import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS} from "./loadActionTypes";
import {selectAllIndexes} from "./indexesSlice";

const initialState = {
  sic: [],
  selected: null,
  status: LOAD_IDLE,
  error: null
}

export const fetchSic = createAsyncThunk('sic/fetchSic', async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/sic/`);
  return  await response.json();
});

const sicSlice = createSlice({
  name: 'sic',
  initialState,
  reducers: {
    setSelectedGroup: (state, action) => {
      state.selected = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSic.pending, (state) => {
        state.status = LOAD_PROGRESS;
      })
      .addCase(fetchSic.fulfilled, (state, action) => {
        state.sic = action.payload;
        state.status = LOAD_SUCCESS;
      })
      .addCase(fetchSic.rejected, (state, action) => {
        state.status = LOAD_FAILED;
        state.error = action.error.message;
      })
  }
});

export default sicSlice.reducer;

export const selectAllSic = (state) => state.sic.sic;

export const selectSicBySlug = createSelector(
  [selectAllIndexes, (state, slug) => slug],
  (sic, slug) => sic.find(sic => sic.slug === slug)
);

export const {setSelectedGroup} = sicSlice.actions;
