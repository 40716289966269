import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS} from "./loadActionTypes";

const initialState = {
  industries: {},
  selected: {},
  status: LOAD_IDLE,
  error: null
}

export const fetchIndustries = createAsyncThunk('sic/fetchIndustries', async (groupId, {getState}) => {
  const {industries} = getState();
  if (industries.industries[groupId]) {
    return industries[groupId];
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}/sic/${groupId}`);
  return  await response.json();

});

const industrySlice = createSlice({
  name: 'industries',
  initialState,
  reducers: {
    setSelectedIndustry: (state, action) => {
      state.selected = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIndustries.pending, (state) => {
        state.status = LOAD_PROGRESS;
      })
      .addCase(fetchIndustries.fulfilled, (state, action) => {
        state.industries[action.meta.arg] = action.payload;
        state.status = LOAD_SUCCESS;
      })
      .addCase(fetchIndustries.rejected, (state, action) => {
        state.status = LOAD_FAILED;
        state.error = action.error.message;
      })
  }
});

export default industrySlice.reducer;

export const selectAllIndustries = (state) => state.industries.industries;

export const selectIndustriesForGroupId = createSelector(
  [selectAllIndustries, (state, groupId) => groupId],
  (industries, groupId) => {
    if (groupId) {
      return industries[groupId];
    } else {
      return [];
    }
  }
);

export const { setSelectedIndustry } = industrySlice.actions;
