import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function About() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>Ticker Study - About page</title>
          <meta
            name="description"
            content="Ticker Study: A comprehensive tool for categorizing and analyzing stock market data, offering insights, trends, and personalized features for investors."
          />
        </Helmet>
      </HelmetProvider>
      <h2>About Ticker Study</h2>

      <p>The Ticker Study application is designed as a comprehensive tool for categorizing
        and analyzing stock market information. Its primary focus is on organizing vast amounts of financial data,
        making it accessible and actionable for both novice and experienced investors. The application
        aggregates data from various sources, including stock prices, trading volumes, financial
        statements, and market news. By categorizing this information, Ticker Study allows users
        to quickly find relevant insights and trends, enabling them to make informed investment decisions.</p>

      <p>In addition to its robust data categorization capabilities, Ticker Study plans to introduce a
        range of new features and enhancements periodically. These may include advanced analytical tools,
        real-time market updates, personalized watchlists, and predictive analytics powered by machine
        learning algorithms. The application’s user-friendly interface and customizable options aim to
        cater to the diverse needs of its users, whether they are looking for basic market summaries
        or in-depth financial analysis. With ongoing updates and improvements, Ticker Study is positioned
        to become an essential resource for anyone interested in the stock market.</p>
    </>
  );
}
